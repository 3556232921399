<template>
  <div id="profile-page">
    <!-- My Details -->
    <vx-card class="mt-base">
      <ul class="page-suggestions-list">
        <li class="page-suggestion flex flex-col lg:flex-row items-center mb-4">
          <div class="mr-3">
            <div class="profile-header-image">
              <img
                v-if="activeUserInfo.photoURL"
                key="onlineImg"
                :src="activeUserInfo.photoURL"
                alt="user-img"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="leading-tight">
            <p class="font-medium">My Details</p>
            <span class="text-xs">Manage account details</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <ProfileChangePassword />
              <vs-button
                v-if="edit"
                icon-pack="feather"
                icon="icon-x"
                color="danger"
                type="filled"
                @click="cancelEdit()"
                >Cancel Edit</vs-button
              >
              <vs-button
                v-else
                icon-pack="feather"
                icon="icon-edit"
                color="primary"
                type="filled"
                @click="editProfile()"
                >Edit Profile</vs-button
              >
            </div>
          </div>
        </li>
      </ul>
    </vx-card>

    <!-- Edit Profile Detail -->
    <vx-card v-if="edit" title="Edit Profile Detail" class="mt-base">
      <ProfileEdit :userData="userDetail" @cancelEdit="cancelEdit" />
    </vx-card>

    <!-- Profile Detail -->
    <vx-card v-else title="Profile Details" class="mt-base">
      <ProfileDetail />
    </vx-card>
  </div>
</template>

<script>
import ProfileDetail from "@/views/components/profile/ProfileDetail";
import ProfileEdit from "@/views/components/profile/ProfileEdit";
import ProfileChangePassword from "@/views/components/profileChangePassword";

export default {
  components: {
    ProfileDetail,
    ProfileEdit,
    ProfileChangePassword,
  },
  data() {
    return {
      edit: false,
      userDetail: {},
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    editProfile() {
      this.userDetail = this.$store.state.AppActiveUser;
      this.edit = true;
    },
    cancelEdit() {
      this.userDetail = this.$store.state.AppActiveUser;
      this.edit = false;
    },
  },
  created() {
    this.userDetail = this.$store.state.AppActiveUser;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
